import React from 'react'
import { About } from '../components/About'
import { SEO } from '../components/SEO'

export default function sobreMi () {
  return (
    <>
      <SEO title='Sobre mí' />
      <About />
    </>
  )
}
